import "./App.css";
import About from "./components/About";
import Blog from "./components/Blog";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/blog" element={<Blog/>} />
          <Route path="/" element={<About/>} />
        </Routes>
      </Router>
  );
}

export default App;
