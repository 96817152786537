import "./SideBar.css";
import HeadshotImage from "../static/me.jpg";
import { Heading, Button, Image, Text } from "@chakra-ui/react";
import SocialLinks from "./SocialLinks";
import { useNavigate } from "react-router-dom";

const SideBar = () => {

  //navigate hook to allow us to change routes
  const navigate = useNavigate();

    return (
      <div className="Side">
        <Heading color="white" size="4xl" fontWeight={1.5} noOfLines={2}>Mahan Mahtabfar</Heading>
        <Heading size="md" color="white" marginTop="3vh">Junior Software Engineer at Amazon</Heading>
        <div className="Headshot">
            <Image 
                borderRadius="full"
                boxSize="100px"
                src={HeadshotImage}
                alt="Mahan on Mt. Rainier!"
            />
          <Text paddingLeft="20px" size="small" color="white">
            I am a senior computer science student at The University of Florida. <br/>
            Upon graduating in the spring, I will be joining Amazon on the S3 Index team. <br/>
            In my spare time, I like to play raquetball and spend time with my girlfriend and family.
          </Text>
        </div>
        <div style={{ marginTop: "5vh"}}>
            <Text color="white">
              I also write a
              <Button paddingLeft="5px" paddingRight="5px" fontSize="20px" color="white" variant="link" onClick={() => navigate("/blog")}>
                <b>blog</b>
              </Button>
              where I share my ideas and learnings.
            </Text>
        </div>
        <SocialLinks />
      </div>
    );
}

export default SideBar;