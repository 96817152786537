import "./Blog.css";
import { Heading, Center } from "@chakra-ui/react";

const Blog = () => {
    return (
        <Center paddingTop="4vh" bg="rgb(21, 21, 21)">
            <Heading fontSize="60px" fontWeight={100} color="white">No Posts Yet...</Heading>
        </Center>
    );
}

export default Blog;