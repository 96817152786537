import "./ExperienceSection.css";
import { SimpleGrid, Box, Text, Icon } from "@chakra-ui/react";
import { SiAmazon } from "react-icons/si";
import { AiFillSmile } from "react-icons/ai";
import { GiTeacher } from "react-icons/gi";
import { ImLab } from "react-icons/im";

const ExperienceSection = () => {

    return (
        <>
            <SimpleGrid width="100%" columns={1} spacing={10} className="About">
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={SiAmazon} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>Amazon</b></Text> 
                        <Text color="white">Software Development Engineer 1</Text>
                        <Text color="white">July 2023</Text>
                    </div>
                </Box>
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={SiAmazon} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>Amazon</b></Text> 
                        <Text color="white">Software Development Engineer Intern</Text>
                        <Text color="white">May 2022 - Aug 2022</Text>
                    </div>
                </Box>
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={AiFillSmile} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>UKG</b></Text>
                        <Text color="white">Software Engineer Intern</Text>
                        <Text color="white">January 2022 - May 2022</Text>
                    </div>
                </Box>
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={AiFillSmile} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>UKG</b></Text>
                        <Text color="white">Software Engineer Intern</Text>
                        <Text color="white">June 2021 - August 2021</Text>
                    </div>
                </Box>
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={GiTeacher} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>University of Florida CISE Department</b></Text>
                        <Text color="white">Teaching Assistant</Text>
                        <Text color="white">August 2020 - May 2021</Text>
                    </div>
                </Box>
                <Box display="flex" padding="10px" overflow="hidden" width="100%" height="20vh" bg="rgb(32, 32, 34)">
                    <Icon marginTop="auto" marginBottom="auto" marginLeft="20px" marginRight="20px" color="white" as={ImLab} w="32px" h="32px"/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Text color="white" fontSize="xl"><b>Schaller Lab</b></Text>
                        <Text color="white">Research Assistant</Text>
                        <Text color="white">August 2019 - May 2022</Text>
                    </div>
                </Box>
            </SimpleGrid>
        </>
    );
};

export default ExperienceSection;