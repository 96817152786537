import "./About.css";
import ExperienceSection from "./ExperienceSection";
import SideBar from "./SideBar";

const About = () => {
    return (
        <div className="AboutPage">
            <SideBar />
            <ExperienceSection />
        </div>
    );
}

export default About;